import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CreatorForm.css";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import emailjs from "@emailjs/browser";
import instagramIcon from '../assets/images/instagram_blue.svg';

function CreatorForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      console.log("Form data:", data);

      const templateParams = {
        from_name: data.name,
        from_email: data.email,
        phone_number: data.phone,
        date_of_birth: data.dateOfBirth,
        instagram: data.instagram,
        twitter: data.twitter,
        to_name: "Admin",
        message: `
          Name: ${data.name}
          Email: ${data.email}
          Phone: ${data.phone}
          Date of Birth: ${data.dateOfBirth}
          Instagram: ${data.instagram}
          Twitter: ${data.twitter}
        `,
      };

      await emailjs.send(
        "service_ljsv2zh",
        "template_u71so5t",
        templateParams,
        "J2G5UGnV7bpNJ0-2p"
      );

      setIsSuccess(true);
      reset();
    } catch (error) {
      console.error("Error details:", error);
      // Keep this alert for errors
      alert("Failed to submit form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="page-container">
      <header className="site-header">
        <div className="header-content">
          <div className="header-logo">
            <Link to="/" className="logo-link">
              <h1>TLINK.ME</h1>
            </Link>
          </div>
        </div>
      </header>

      <div className="creator-form-container">
        {!isSuccess ? (
          <>
            <h1>BECOME A CREATOR</h1>
            <p>
              Fill out your information below and our team will reach out to
              you shortly regarding your approval.
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-field">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Enter your full name"
                  {...register("name", { required: true })}
                />
              </div>

              <div className="form-field">
                <label>Email Address</label>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  {...register("email", { required: true })}
                />
              </div>

              <div className="form-field">
                <label>Phone Number</label>
                <PhoneInput
                  country={"us"}
                  onChange={(phone) => setValue("phone", phone)}
                  inputProps={{
                    required: true,
                  }}
                />
              </div>

              <div className="form-field">
                <label>Date of Birth</label>
                <input
                  type="date"
                  {...register("dateOfBirth", { required: true })}
                />
              </div>

              <div className="form-field">
                <label>Instagram</label>
                <input
                  type="text"
                  placeholder="@yourusername"
                  {...register("instagram", { required: true })}
                />
                <div className="form-note">*Send us a direct message on Instagram for confirmation.</div>
              </div>

              <div className="form-field">
                <label>X (Twitter)</label>
                <input
                  type="text"
                  placeholder="@yourusername"
                  {...register("twitter")}
                />
              </div>

              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "SUBMITTING..." : "Submit"}
              </button>
            </form>
          </>
        ) : (
          <div className="success-message">
            <h2>Application Submitted!</h2>
            <p>Thank you for your interest. Our team will review your application and get back to you shortly.</p>
          </div>
        )}
      </div>

      <footer className="site-footer">
        <div className="footer-content">
          <div className="footer-left">
            <span className="footer-title">FOLLOW US</span>
            <div className="footer-social">
              <img src={instagramIcon} alt="Instagram" className="footer-instagram-icon" />
              <a href="https://instagram.com/tlink.me" target="_blank" rel="noopener noreferrer" className="footer-handle">TLINK.ME</a>
            </div>
          </div>
          <div className="footer-center">
            <div className="footer-logo">TLINK.ME</div>
          </div>
          <div className="footer-right">
            <div className="footer-copyright">
              ALL RIGHTS RESERVED.
              <span className="footer-year">2025</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default CreatorForm;
