import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import CreatorForm from "./Components/CreatorForm";
import HomePage from "./pages/HomePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:username" element={<UserPage />} />
        <Route path="/creators" element={<CreatorForm />} />
      </Routes>
    </Router>
  );
}

function UserPage() {
  const { username } = useParams();
  const [profile, setProfile] = useState({
    name: null,
    photo: null,
    bio: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const CORS_PROXIES = [
      'https://api.codetabs.com/v1/proxy?quest=',
      'https://api.allorigins.win/raw?url=',
      'https://thingproxy.freeboard.io/fetch/'
    ];

    const fetchWithProxy = async (proxyUrl) => {
      const response = await fetch(`${proxyUrl}https://t.me/${username}`);
      if (!response.ok) throw new Error('Failed to fetch');
      const text = await response.text();
      
      if (text.includes('tgme_page_empty')) {
        throw new Error('User not found');
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");

      const photoElement = doc.querySelector(".tgme_page_photo_image");
      const nameElement = doc.querySelector(".tgme_page_title span");
      const bioElement = doc.querySelector(".tgme_page_description");

      return {
        photo: photoElement?.src || null,
        name: nameElement?.textContent?.trim() || null,
        bio: bioElement?.textContent?.trim() || null
      };
    };

    const fetchProfile = async () => {
      for (const proxy of CORS_PROXIES) {
        try {
          const data = await fetchWithProxy(proxy);
          setProfile(data);
          setLoading(false);
          return;
        } catch (error) {
          if (error.message === 'User not found') {
            setError({ message: 'User not found' });
            setLoading(false);
            return;
          }
          console.error(`Error with proxy ${proxy}:`, error);
          continue;
        }
      }
      setError(new Error('All proxies failed'));
      setLoading(false);
    };

    fetchProfile();
  }, [username]);

  const handleSendMessage = () => {
    window.location.href = `https://t.me/${username}`;
  };

  const LoadingState = () => (
    <>
      <div className="profile-photo-placeholder shimmer"></div>
      <div className="name-placeholder shimmer"></div>
      <h2 className="username">@{username}</h2>
      <div className="bio-placeholder shimmer"></div>
    </>
  );

  return (
    <div className="main-container">
      <style>{styles}</style>
      <header className="header">
        <div className="header-container">
          <div className="header-icon"></div>
          <h1 className="header-title">Telegram</h1>
        </div>
      </header>
      <div className="card">
        {loading && <LoadingState />}
        {!loading && error && (
          <h1 className="name">
            {error.message === 'User not found' ? 'User not found.' : 'Unable to load profile. Please try again later.'}
          </h1>
        )}
        {!loading && !error && (
          <>
            {profile.photo && (
              <img
                src={profile.photo}
                alt={profile.name || username}
                className="profile-photo"
              />
            )}
            <h1 className="name">{profile.name || `@${username}`}</h1>
            <h2 className="username">@{username}</h2>
            {profile.bio && <p className="bio">{profile.bio}</p>}
          </>
        )}
        <button onClick={handleSendMessage} className="button" disabled={loading}>
          SEND MESSAGE
        </button>
      </div>
    </div>
  );
}

const styles = `
  :root {
    --card-bg: white;
    --text-color: #000000;
    --text-secondary: #787A7C;
    --text-tertiary: #000000;
    --button-gradient: linear-gradient(180deg, #006FC9 0%, #006FC9 100%);
    --button-hover-gradient: linear-gradient(180deg, #006FC9 0%, #00569c 100%);
    --background-text-opacity: 0.2;
    --bg-gradient: linear-gradient(180deg, #C5CF9E 0%, #579E73 100%);
    --header-bg: #ffffff;
    --shimmer-bg: #f6f7f8;
    --shimmer-highlight: rgba(255, 255, 255, 0.8);
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --card-bg: #1E1E1E;
      --text-color: #ffffff;
      --text-secondary: #787A7C;
      --text-tertiary: #ffffff;
      --button-gradient: linear-gradient(180deg, #1C93E3 0%, #1C93E3 100%);
      --button-hover-gradient: linear-gradient(180deg, #1C93E3 0%, #00569c 100%);
      --background-text-opacity: 0.2;
      --bg-gradient: linear-gradient(180deg, #000000 0%, #000000 100%);
      --header-bg: #1E1E1E;
      --shimmer-bg: #2a2a2a;
      --shimmer-highlight: rgba(255, 255, 255, 0.1);
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }

  .shimmer {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, var(--shimmer-bg) 4%, var(--shimmer-highlight) 25%, var(--shimmer-bg) 36%);
    background-size: 1000px 100%;
  }

  .profile-photo-placeholder {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto 16px auto;
  }

  .name-placeholder {
    width: 200px;
    height: 32px;
    border-radius: 4px;
    margin: 0 auto 4px auto;
  }

  .bio-placeholder {
    width: 280px;
    height: 30px;
    border-radius: 4px;
    margin: 0 auto 20px auto;
  }

  body {
    margin: 0;
    font-family: 'Lexend', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-gradient);
    min-height: 100vh;
  }

  .header {
    width: 100%;
    background: var(--header-bg);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .header-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px 20px 10px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header-icon {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/telegraml.svg');
  }

  @media (prefers-color-scheme: dark) {
    .header-icon {
      background-image: url('/telegramd.svg');
    }
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-tertiary);
    margin: 0;
  }

  .card {
    margin-left:16px;
    margin-right:16px;
    position: relative;
    background: var(--card-bg);
    margin-top: 80px;
    padding: 32px 0px 22px 0px;
    border-radius: 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    text-align: center;
    min-width: 320px;
    width: 90%;
    max-width: 400px;
  }

  .name {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.9px;
    margin: 0 0 8px 0;
    color: var(--text-color);
  }

  .username {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-secondary);
    margin: 0 0 16px 0;
  }

  .bio {
    font-size: 16px;
    color: var(--text-tertiary);
    margin: 10px 0;
    padding: 0px 0px 20px 0px;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .button {
    background-image: var(--button-gradient);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
  }

  .loading {
    color: var(--text-secondary);
    font-size: 14px;
    margin: 20px;
  }

  .error {
    color: red;
    font-size: 14px;
    margin: 20px;
  }

  .profile-photo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .content {
    margin-top: 100px;
    text-align: center;
    color: var(--text-color);
  }

  .content h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .content p {
    font-size: 16px;
    color: var(--text-secondary);
  }

  @media screen and (max-width: 600px) {
    .header-container {
      padding: 10px 16px;
    }

    .card {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`;

export default App;